.App {  
  display: flex;
  flex-direction: column;

} 

@media (orientation: portrait){
  /* デバイスが縦向きの場合の記述 */
  .App {
    width: 100%;
    max-width: 414px;
    min-width: 350px;
  
    text-align: center;
    height:100vh;
    height : 100svh;
  
  }
}

@media (orientation: landscape){
    /* デバイスが横向きの場合の記述 */
    .App {
      width: 100vw;
      width: 100svw;
    
      text-align: center;
      height : 100vh;
      height : 100svh;
    } 
}

.App-landscape {
  width: 100vw;
  width: 100dvh;

  text-align: center;
  height : 100vh;
  height : 100dvw;
  
  
  display: flex;
  flex-direction: column;

}  

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-link {
  color: #61dafb;
}

.centerGif{
  width: 50vw;
  margin-left: auto;
  margin-right: auto;
  
}

.centerPhotoButtonAnnounce{
  width: 30vw;
  margin: 30vh auto 0;
  
  text-align: center;

}

.centerPhotoButton{
  width: 25vw;
  margin:3vh auto 0;
  
  text-align: center;

}

.centerPhotoButtonAnnounceTap{
  width: 7vw;
  margin:1vh auto 0;
  text-align: center;
}

.waitMessage{
  width: 20vw;
  margin-top: 40vh;
  margin-left: auto;
  margin-right: auto;
}

.waitMessageAnim{
  width: 30vw;
  margin-top: 1vh;
  margin-left: auto;
  margin-right: auto;
}

.logo {

  height: 7vh;
  height: 7dvh;
  width:auto;

}

.attention{
  max-width: 248px;
  width: 60vw;
  margin-left: auto;
  margin-right: auto;
}

.acceptbutton{
  width: 85vw;
  max-width: 352px;
  margin-top: 5vh;
  margin-left: auto;
  margin-right: auto;
}

.box {
  width: 85vw; 
  max-width: 352px;              
  max-height: 32vh;               
  overflow-y: scroll;          /* 縦方向にスクロール可能にする */
  margin-left: auto;
  margin-right: auto;
}

.scroll-a::-webkit-scrollbar {
  width: 6px; /* 横幅 */
}

.scroll-a::-webkit-scrollbar-thumb {
  background: #000000; /* ハンドルの色 */
}

.scroll-a::-webkit-scrollbar-track {
  background: #ffffff; /* 背景色 */
}


.selectButton{
  width:60vw;
  height:15vh;
  margin-left: auto;
  margin-right: auto;

}


/* ------ ここから修正分のFotter ------ */

.link a{
  text-decoration: underline;
  text-decoration-color: black;
}

.link_w a{
  text-decoration: underline;
  text-decoration-color: white;
}

.Header{
  height : 10vh;
  height : 10svh;
  

  display: flex;
  align-items: center;
  justify-content: center;
}

.Contents{
  height : 80vh;
  height : 80svh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ContentsBox{
  margin:20%,0%
}

.ContentsBox_JP{
  margin: 0 17%; 
}
.ContentsBox_En{
  margin: 0 20%; 
}

.Fotter{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  bottom : 0;
}

.FotterImage {
  max-width: 97%; /* 画像の最大幅を100%に設定 */
  height: auto; /* 高さを自動調整 */
  display: block; /* ブロック要素に変更 */
  margin: 0 auto; /* 左右のマージンを自動で設定 */
  padding: 0 5%;
}

.GIF_Contents{
  max-width: 40%;
}


.BackGround_w {
  background-color: #ebebeb ;
  width: 100%;                
  min-height: 100vh;
}

.BackGround_g {
  background-color: #2e2d2C;
  width: 100%;                
  min-height: 100vh;
}

.App-link {
  color: #61dafb;
}

.Border_red{
  color: red;
  border: solid;
}
.Border_green{
  color: green;
  border: solid;
}
.Border_Blue{
  color: blue;
  border: solid;
}
